/* Component Dependencies */
var adobeTargetHeadContentTemplate = require('templates/adobeTargetHeadContent.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'adobeTargetHeadContent',
  template: {
    'adobeTargetHeadContent': adobeTargetHeadContentTemplate
  }
});
